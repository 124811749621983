aside {
    position: fixed;
    // position: relative;
    z-index: 99;
    bottom: 0px;
    left: 0px;

    display: none;
    background: white;
    width: 100vw;
    height: calc(100vh - 160px);

    &.open {
        display: flex;
    }


    @media (min-width: $breakpoint-md) {

        background: transparent;
        display: flex;
        top: 180px;
        left: 0px;
        width: auto;
    }

    nav {
        padding: 30px; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 100%;

    }

    ul {

        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 2.2;

        @media (min-width: $breakpoint-md) {

           align-items: flex-start;
           line-height: inherit;
        }
    }

    .button {
        // position: absolute;
        // bottom: 30px;
        display: none;
    }
}