$lichtgrijs : rgb(230,230,230);
$lichtergrijs : rgb(236,236,236);
$middelgrijs : rgb(166,166,166);
$grijs : rgb(220,220,220);
$donkergrijs : rgb(100,100,100);
$zwart : rgb(51,51,51);
$wit : rgb(255,255,255);

$darkBlue : #1f1351;

