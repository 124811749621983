.container {

	width: calc(100% - 2rem);
	position: relative;
	margin: 0 auto;
	flex-direction: column;

	@media (min-width : $breakpoint-sm) {
		width: 600px;
	}

	// @media (min-width : $breakpoint-md) {
	// 	width: 640px;
	// }

	@media (min-width : $breakpoint-bax) {
		width: 680px;
	}

	@media (min-width : $breakpoint-lg) {
		width: 920px;
	}
}

.full-container {

	width: 100%;

	@media (min-width : $breakpoint-sm) {
		width: calc(100% - 2rem);
	}

	@media (min-width : $breakpoint-md) {
		width: calc(100% - 4rem);
	}
}

.large-container {

	width: calc(100% - 1.5rem);
	position: relative;
	margin: 0 auto;
	background: #fff;

	@media (min-width : $breakpoint-sm) {
		width: 680px;
	}


	// @media (min-width : $breakpoint-md) {
	// 	width: 680px;
	// }

	@media (min-width : $breakpoint-bax) {
		width: 760px;
	}

	@media (min-width : $breakpoint-lg) {
		width: 960px;
	}

	@media (min-width : $breakpoint-xl) {
		width: 1080px;
	}
}

.small-container {

	width: calc(100% - 1.5rem);
	position: relative;
	margin: 0 auto;
	background: #fff;

	@media (min-width : $breakpoint-sm) {
		width: calc(100% - 4rem);
	}

	@media (min-width : $breakpoint-md) {
		width: 640px;
	}

	@media (min-width : $breakpoint-bax) {
		width: 640px;
	}

	@media (min-width : $breakpoint-lg) {
		width: 640px;
	}
}


