#home_gallery {

    margin-top: 2rem;
 
   

    @media (min-width: $breakpoint-sm) {

        // width: 100%;
        // min-height: 900px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;

    }

   .gallery-item {

        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .image-container {

            display: flex;
            position: relative;
            width: 100%;
            height: auto;
            overflow: hidden;

            @media (max-width: $breakpoint-sm) {
                margin-bottom: .75rem;
            } 

            img {
                width: 100%;
                // min-height: 100%;
            }

            .image-overlay {
             
                position: absolute;
                width: calc(100% - 1.5rem);
                height: calc(100% - 1.5rem);
                top: 0;
                left: 0;
                opacity: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                background: #000;
                padding: .75rem;
                color: #fff;
    
                
            }

            &:hover {

                .image-overlay {
                    opacity: .5;
                }

                .image-description {
                    
                    bottom: 0;
                }
            }

            .image-description {
                position: absolute;
                bottom: -140px;
                width: 100%;
                left: 0;
                padding: .75rem;
                color: white;
                transition: bottom 250ms ease-out;
                display: flex;
                flex-direction: column;
                line-height: 1.5;
          
                

                h3 {
                    line-height: 1.175;
                    padding-bottom: 0.5rem;
                }

                a {
                    color: white;
                    border: none;
                }

                svg {

                    position: absolute;
                    right: 30px;
                    top: 50px;
                    width: 60px;
                    height: 75px;
                    stroke: white;
                    fill: white;
                }

            }
        }

        

        &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: span 5;
            grid-row-start: 1;
            grid-row-end: span 4;
            justify-content: flex-end;

        }
    
        &:nth-child(2) {
            grid-column-start: 6;
            grid-column-end: span 2;
            grid-row-start: 1;
            grid-row-end: span 2;
            justify-content: flex-end;
        }

        &:nth-child(3) {
            grid-column-start: 10;
            grid-column-end: span 3;
            grid-row-start: 4;
            grid-row-end: span 2;
            justify-content: flex-start;
        }

        &:nth-child(4) {
            grid-column-start: 10;
            grid-column-end: span 3;
            grid-row-start: 1;
            grid-row-end: span 3;
            justify-content: flex-end;
        }

    

        &:nth-child(5) {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 5;
            grid-row-end: span 3;
            justify-content: flex-start;
            // margin-top: 26px;
        
        }

        &:nth-child(6) {
            // grid-column-start: 10;
            // grid-column-end: span 3;
            // grid-row-start: 6;
            // grid-row-end: span 3;
            // justify-content: flex-start;
        }

        &:nth-child(7) {
            grid-column-start: 6;
            grid-column-end: span 4;
            grid-row-start: 6;
            grid-row-end: span 3;
            justify-content: flex-start;
        }

        &:nth-child(8) {
            grid-column-start: 10;
            grid-column-end: span 2;
            grid-row-start: 6;
            grid-row-end: span 3;
            justify-content: flex-start;
        }

        &:nth-child(9) {
            grid-column-start: 6;
            grid-column-end: span 4;
            grid-row-start: 3;
            grid-row-end: span 3;
            justify-content: flex-start;
        }

       

    }

    
}