footer {

    padding-bottom: 6rem;

    > section {
            
        display: flex;
        flex-direction: column;
        

        @media (min-width : $breakpoint-md) {
            flex-direction: row;
        }

        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            line-height: 1.84;
            align-items: center;

            @media (min-width: $breakpoint-lg) {
                align-items: flex-start;
            }
        }
    }
}