$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-bax: 1160px;
$breakpoint-lg: 1360px;
$breakpoint-xl: 1700px;
$breakpoint-xxl: 2000px;
// $mobile-max: 760px;
// $portrait-max: 900px;
// $landscape-max: 1300px;
// $huge-min: 1700px;


.shown-till-md {
    display: flex;
    @media (min-width: $breakpoint-md) {
        display: none;
    }
}


.shown-from-md {
    display: none !important;
    @media (min-width: $breakpoint-md) {
        display: inline;
    }
}


.shown-from-lg {
    display: none;
    @media (min-width: $breakpoint-lg) {
        display: inline;
    }
}
