.blog_header {

    // margin-bottom: 120px;
    // padding: 0 90px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;

   img.thumb {
       width: 7.5rem;
       height: 7.5rem;
       border-radius: 50%;

   }

    h1 {
        text-align: center;
        margin-bottom: .75rem;
        margin-top: 1.25rem;
    }

    .date {
        margin-bottom: 2rem;
        // font-style: italic;
    }

    // > div {

    //     img {
    //         max-width: 100%;
    //     }
    // }

    
}

section.images {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 60px 0;

    > img {
        max-width: 100%;
        max-height: 70vh;
        padding: 0 15px;

        &:first-child {
            padding-left: 0;

        }

        &:last-child {
            padding-right: 0;
            
        }
    }

}